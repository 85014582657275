export enum MenuData {
    REGISTRY,
    USERS,
    SERVANTS,
    AREAS,
    DEVICES,
    PROOFS,
    DOCUMENTS,
    DOCUMENTTYPES,
    TASKS,
    HOME,
    MANAGMENT,
    ADMINISTRATION,
    AUDITS,
}
