import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'

export class BaseService {
    constructor(public httpClient: HttpClient, public collection: string) {}

    getAll() {
        return this.httpClient.get(environment.baseUrl + this.collection + '/all', {})
    }

    getById(id: number) {
        return this.httpClient.get(environment.baseUrl + `${this.collection}/${id}`, {})
    }

    save(data: any) {
        // console.log('data', data);
        // if (data.id) {
        //   return this.httpClient.put(
        //     environment.baseUrl + this.collection + '/update',
        //     data
        //   );
        // }
        return this.httpClient.post(environment.baseUrl + this.collection + '/save', data)
    }

    delete(id: number) {
        return this.httpClient.delete(environment.baseUrl + `${this.collection}/${id}`)
    }
}
