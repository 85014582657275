import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IAlfrescoDocumentData } from '../documents/interfaces/alfresco.interface';
import { DocumentService } from '../documents/services/document.service';

@Injectable({
    providedIn: 'root',
})
export class PolicesService {
    privatePolices: IAlfrescoDocumentData;

    constructor(public httpClient: HttpClient, private injector: Injector) {}

    async loadPolices() {
        let documentService = this.injector.get(DocumentService);
        this.privatePolices = (await documentService.getAlfrescoDocVersion(environment.policyAlfrescoId).toPromise()) as IAlfrescoDocumentData;
    }

    getAboutUsText() {
        return this.httpClient.get('assets/polices/about-us.html', { responseType: 'text' });
    }

    getCookiesText() {
        return this.httpClient.get('assets/polices/cookies.html', { responseType: 'text' });
    }

    getRequirementsText() {
        return this.httpClient.get('assets/polices/requirements.html', { responseType: 'text' });
    }
}
