import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { ROUTES } from './shared/constants/routes';
import { MenuData } from './shared/enums/menu-data.enum';

const ALL_ROUTES: Routes = [
    {
        path: ROUTES.HOME_ADMIN.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/home-admin/home-admin.module').then((m) => m.HomeAdminModule),
        data: {
            ACTIVE_MENU: MenuData.REGISTRY,
        },
    },
    {
        path: ROUTES.HOME_USER.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/home-user/home-user.module').then((m) => m.HomeUserModule),
        data: {
            ACTIVE_MENU: MenuData.HOME,
        },
    },
    {
        path: ROUTES.USERS.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/users/users.module').then((m) => m.UsersModule),
        data: {
            ACTIVE_MENU: MenuData.ADMINISTRATION,
        },
    },
    {
        path: ROUTES.SERVICES.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/servants/servants.module').then((m) => m.ServantsModule),
        data: {
            ACTIVE_MENU: MenuData.MANAGMENT,
        },
    },
    {
        path: ROUTES.AREAS.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/areas/areas.module').then((m) => m.AreasModule),
        data: {
            ACTIVE_MENU: MenuData.MANAGMENT,
        },
    },
    {
        path: ROUTES.DEVICES.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/devices/devices.module').then((m) => m.DevicesModule),
        data: {
            ACTIVE_MENU: MenuData.MANAGMENT,
        },
    },
    {
        path: ROUTES.PROOFS.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/proofs/proofs.module').then((m) => m.ProofsModule),
        data: {
            ACTIVE_MENU: MenuData.MANAGMENT,
        },
    },
    {
        path: ROUTES.DOCUMENTS.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/documents/documents.module').then((m) => m.DocumentsModule),
        data: {
            ACTIVE_MENU: MenuData.DOCUMENTS,
        },
    },
    {
        path: ROUTES.DOCUMENTTYPES.path,
        loadChildren: () => import('./modules/document-types/document-types.module').then((m) => m.DocumentTypesModule),
        data: {
            ACTIVE_MENU: MenuData.MANAGMENT,
        },
    },
    {
        path: ROUTES.AUDITS.path,
        loadChildren: () => import('./modules/audits/audits.module').then((m) => m.AuditsModule),
        data: {
            ACTIVE_MENU: MenuData.AUDITS,
        },
    },
    {
        path: ROUTES.TASKS.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/tasks/tasks.module').then((m) => m.TasksModule),
        data: {
            ACTIVE_MENU: MenuData.TASKS,
        },
    },
    {
        path: ROUTES.POLICIES.path,
        loadChildren: () => import('./modules/policies/policies.module').then((m) => m.PoliciesModule),
    },
    {
        path: ROUTES.RESTRICTED.path,
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/user-restriction/user-restriction.module').then((m) => m.UserRestrictionModule),
    },
    { path: '', redirectTo: ROUTES.HOME_USER.fullpath, pathMatch: 'full' },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(ALL_ROUTES, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
