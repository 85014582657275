// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: window['env']['production'] || false,
    keycloakUrl: window['env']['keycloakUrl'] || 'https://keycloak.gdlabdev.labsdemo.es/auth/',
    keycloakRealm: window['env']['keycloakRealm'] || 'gdlab',
    keycloakClientId: window['env']['keycloakClientId'] || 'gdlab-frontend',
    servolabUrl: window['env']['servolabUrl'] || 'https://servolabws.gdlabdev.labsdemo.es/',
    baseUrl: window['env']['baseUrl'] || 'https://backend.gdlabdev.labsdemo.es/',
    policyAlfrescoId: window['env']['policyAlfrescoId'] || 'b099cf92-8a4d-4a66-bab2-2143d38fafc0',
    alfrescoBaseUrl: window['env']['alfrescoBaseUrl'] || 'https://alfresco.gdlabdev.labsdemo.es',
};
