import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

@Injectable({
    providedIn: 'root',
})
export class ServoRoleService {
    constructor(public httpClient: HttpClient) {}

    getRoles(login: string) {
        return this.httpClient.get(environment.servolabUrl + 'servo-role/' + login, {})
    }
}
