import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular'
import { UserService } from '../modules/users/services/users.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(protected readonly router: Router, protected readonly keycloakService: KeycloakService, protected readonly userService: UserService) {
        super(router, keycloakService)
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        if (!this.authenticated) {
            await this.keycloakService.login({
                redirectUri: window.location.origin,
            })
        }

        await this.userService.checkCurrentUserExists()
        return this.authenticated
    }
}
