import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ShellMainComponent } from './components'
import { routes } from './shell.routes'

@NgModule({
    declarations: [ShellMainComponent],
    imports: [CommonModule, RouterModule.forChild(routes)],
})
export class ShellModule {}
