import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'gdlab-shell',
    templateUrl: './shell-main.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ShellMainComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {
        console.log('origin', window.location.origin);
        if (this.isLocalhost()) {
            // this.router.navigateByUrl(ROUTES.TASKS.fullpath, { replaceUrl: true });
            // this.router.navigateByUrl(ROUTES.DOCUMENTS.fullpath, { replaceUrl: false });
            this.router.navigate(['/documents/details'], {
                queryParams: { documentId: 9 },
            });
            // this.router.navigate(['/tasks/information'], {
            //     queryParams: { taskId: 9 },
            // });
        }
    }

    private isLocalhost(): boolean {
        return window.location.origin.indexOf('localhost') > -1;
    }
}
