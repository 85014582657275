/**
 * Shared router constants.
 */
export const ROUTES = {
    ROOT: {
        path: '/',
    },
    HOME_ADMIN: {
        path: 'admin',
        fullpath: '/admin',
        DASHBOARD: {
            path: 'dashboard',
            fullpath: '/admin/dashboard',
        },
    },
    HOME_USER: {
        path: 'dashboard',
        fullpath: '/dashboard',
    },
    USERS: {
        path: 'users',
        fullpath: '/users',
        USERS_DETAIL: {
            path: 'details',
            fullpath: '/users/details',
        },
    },
    SERVICES: {
        path: 'services',
        fullpath: '/services',
        SERVICES_DETAIL: {
            path: 'details',
            fullpath: '/services/details',
        },
    },
    AREAS: {
        path: 'areas',
        fullpath: '/areas',
        AREAS_DETAIL: {
            path: 'details',
            fullpath: '/areas/details',
        },
    },
    DEVICES: {
        path: 'devices',
        fullpath: '/devices',
        DEVICES_DETAIL: {
            path: 'details',
            fullpath: '/devices/details',
        },
    },
    PROOFS: {
        path: 'proofs',
        fullpath: '/proofs',
        PROOFS_DETAIL: {
            path: 'details',
            fullpath: '/proofs/details',
        },
    },
    DOCUMENTS: {
        path: 'documents',
        fullpath: '/documents',
        DOCUMENTS_DETAIL: {
            path: 'details',
            fullpath: '/documents/details',
        },
    },
    DOCUMENTTYPES: {
        path: 'docType',
        fullpath: '/docType',
        DOCUMENTTYPES_DETAIL: {
            path: 'details',
            fullpath: '/docType/details',
        },
    },
    TASKS: {
        path: 'tasks',
        fullpath: '/tasks',
        TASKS_DETAIL: {
            path: 'details',
            fullpath: '/tasks/details',
        },
        TASKS_INFO: {
            path: 'information',
            fullpath: '/tasks/information',
        },
        REASIGN: {
            path: 'reasign',
            fullpath: '/tasks/reasign',
        },
    },
    AUDITS: {
        path: 'audits',
        fullpath: '/audits',
    },
    POLICIES: {
        path: 'policies',
        fullpath: '/policies',
        ABOUT_US: {
            path: 'about',
            fullpath: '/policies/about',
        },
        COOKIES: {
            path: 'cookies',
            fullpath: '/policies/cookies',
        },
        LEGAL: {
            path: 'legal',
            fullpath: '/policies/legal',
        },
        REQUIREMENTS: {
            path: 'requirements',
            fullpath: '/policies/requirements',
        },
    },
    RESTRICTED: {
        path: 'restricted',
        fullpath: '/restricted',
    },
};
